import React from 'react';
import { Image } from 'rebass/styled-components';
import { format, formatDistance } from 'date-fns';

import { Timeline, TimelineItem } from '@views/resume/timeline';
import { SectionTitle } from '@views/resume/styles';

import ClearTaxLogo from '@images/resume/cleartax-logo-small.png';
import SabreLogo from '@images/resume/sabre-logo-small.png';
import AtlassianLogo from '@images/resume/atlassian-logo-small.png';

const DATE_FORMAT_EXP = `MMM yyyy`;

const EXPERENCE = {
  ATLASSIAN: {
    SSE_DEV_INFRA: new Date(`2024-10-01`),
    SSE_MARKETPLACE: { start: new Date(`2023-04-01`), end: new Date(`2024-09-30`) },
    SDE2: { start: new Date(`2020-09-07`), end: new Date(`2023-03-31`) },
  },
  CLEARTAX: {
    SE1: { start: new Date(`2016-12-12`), end: new Date(`2018-10-31`) },
    SE2: { start: new Date(`2018-11-01`), end: new Date(`2019-09-30`) },
    SE3: { start: new Date(`2019-10-01`), end: new Date(`2020-08-28`) },
  },
  SABRE: { start: new Date(`2016-07-04`), end: new Date(`2016-12-11`) },
};

const ExperienceDuration = ({ experience }: { experience: { start: Date; end: Date } }) => {
  return (
    <small>
      {format(experience.start, DATE_FORMAT_EXP)} - {format(experience.end, DATE_FORMAT_EXP)} (
      {formatDistance(experience.start, experience.end)})
    </small>
  );
};

export const WorkExperience: React.FC = () => {
  return (
    <section>
      <SectionTitle>Experience</SectionTitle>
      <Timeline>
        <TimelineItem
          icon={
            <a href='https://atlassian.com/' title='Atlassian'>
              <Image src={AtlassianLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              Senior Software Engineer @{' '}
              <a href='https://atlassian.com/' title='Atlassian' className='subtle'>
                Atlassian
              </a>{' '}
              - Dev Infra
            </h3>,
            <small key='duration'>
              {format(EXPERENCE.ATLASSIAN.SSE_DEV_INFRA, DATE_FORMAT_EXP)} - Present{' '}
              <span className='media-hide-print'>
                ({formatDistance(EXPERENCE.ATLASSIAN.SSE_DEV_INFRA, new Date())})
              </span>
            </small>,
          ]}
        >
          <p>
            Working on Dev Infra - Test Foundations shaping how tests are authored, managed, and tracked across
            Atlassian.{' '}
          </p>
          <p>
            Worked on improving Jest unit test runtimes, <strong>reducing CI execution time by 40%</strong> for design
            system and platform frontend components.
          </p>
          <p>
            Contributing to internal Atlassian scalable testing framework, focused on improving Test Confidence,
            Performance, Resilience & Cost. Developing a test resilience score to assess test stability and predict
            flakiness, enhancing reliability across the testing ecosystem.
          </p>
        </TimelineItem>
        <TimelineItem
          label={[
            <h3 key='title'>
              Senior Software Engineer @{' '}
              <a href='https://atlassian.com/' title='Atlassian' className='subtle'>
                Atlassian
              </a>{' '}
              - Marketplace
            </h3>,
            <ExperienceDuration experience={EXPERENCE.ATLASSIAN.SSE_MARKETPLACE} key='duration' />,
          ]}
        >
          <p>
            Led the Store (consumer) side of <em>Project Mosaic</em>, a year-long re-architecture initiative for{' '}
            <a href='https://marketplace.atlassian.com/' title='Atlassian Marketplace' rel='noreferrer nofollow'>
              Atlassian Marketplace
            </a>{' '}
            reliability. <br />
            Designed and introduced a backend-for-frontend (BFF) layer to handle SSR, <strong>GraphQL APIs</strong>, and
            integrations with re-architected services. Also built orchestration for app installations, coordinating APIs
            across multiple internal backend services.
          </p>
        </TimelineItem>
        <TimelineItem
          label={[
            <h3 key='title'>
              Software Development Engineer II @{' '}
              <a href='https://atlassian.com/' title='Atlassian' className='subtle'>
                Atlassian
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.ATLASSIAN.SDE2} key='duration' />,
          ]}
        >
          <p>
            Contributed to Atlassian Marketplace, enabling app discovery and purchases for Jira, Confluence, etc; Worked
            on migrating public app listing pages from a Scala MVC monolith to a modern React, Redux, and Flow.js stack.
          </p>
          <p>
            Led <strong>SEO and performance improvements</strong> for high-traffic pages by onboarding onto
            Atlassian&apos;s Server-side Rendering platform. Implemented code splitting, caching, and asset
            optimizations to <strong>enhance page load speeds and user experience</strong>.
          </p>
        </TimelineItem>
      </Timeline>
      <Timeline>
        <TimelineItem
          icon={
            <a href='https://cleartax.in/' title='ClearTax India'>
              <Image src={ClearTaxLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              Software Engineer III @{' '}
              <a href='https://cleartax.in/' title='ClearTax India' className='subtle'>
                cleartax.in
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.CLEARTAX.SE3} key='duration' />,
          ]}
        >
          <p>
            Worked on the <strong>front-end platform</strong> team, architecting and maintaining core services for
            stable, high-quality financial products.
          </p>
          <p>
            Contributed to the B2B <strong>design system</strong>, feature flag service, single sign-on, and{' '}
            <strong>developer tools</strong>, while establishing standards for code quality, performance, and security.
          </p>
        </TimelineItem>
        <TimelineItem
          label={[
            <h3 key='title'>
              Software Engineer II @{' '}
              <a href='https://cleartax.in/' title='ClearTax India' className='subtle'>
                cleartax.in
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.CLEARTAX.SE2} key='duration' />,
          ]}
        >
          <p>
            Worked as part of a cross-functional team owning the account management, reporting and filing modules of{' '}
            <a href='https://cleartax.in/gst?ref=aditimohanty.com' target='_blank' rel='noreferrer nofollow'>
              ClearTax GST
            </a>{' '}
            (business GST compliance software).
          </p>
        </TimelineItem>
        <TimelineItem
          connector={false}
          label={[
            <h3 key='title'>
              Software Engineer @{' '}
              <a href='https://cleartax.in/?ref=aditimohanty.com' title='ClearTax India' className='subtle'>
                cleartax.in
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.CLEARTAX.SE1} key='duration' />,
          ]}
        >
          <p>
            Worked on ClearTax&apos;s consumer front-end team across two filing seasons, primarily contributing to the{' '}
            <a
              href='https://cleartax.in/income-tax-efiling'
              title='Income Tax E-filing by ClearTax'
              rel='noreferrer nofollow'
            >
              Consumer Tax Filing
            </a>{' '}
            product. Also built a content pipeline for article publishing and developed widgets and finance calculators
            to enhance user engagement.
          </p>
        </TimelineItem>
      </Timeline>
      <Timeline id='experience-timeline--sabre'>
        <TimelineItem
          connector={false}
          icon={
            <a href='https://www.sabre.com/' title='Sabre Corporation'>
              <Image src={SabreLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              Associate Software Developer @{' '}
              <a href='https://www.sabre.com/' title='Sabre Corporation' className='subtle'>
                Sabre
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.SABRE} key='duration' />,
          ]}
        >
          <p>
            Development & QA for GetThere (Internet-based corporate travel procurement and supply services for
            businesses and travel suppliers) by Sabre.
          </p>
        </TimelineItem>
      </Timeline>
    </section>
  );
};
